






















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Global, Reports, User, ProjectStreams } from '@/store';
import API, { Types } from '@/modules/API';
import DatePicker from '@/pages/reports/components/DatePicker.vue';
import moment from 'moment-timezone';
import SatecInformation from '@/pages/reports/components/SatecInformation.vue';
import PropUtils from '@/modules/PropUtils';

@Component({
  components: {
    DatePicker,
    SatecInformation
  }
})
export default class SatecPowerConsumptionReport extends Vue {
  @Reports.State('reportsList') devices;
  @Reports.Getter('commissionedCabinets_map') commissionedCabinets_map;
  @Reports.Getter('cabinets') cabinets;
  @Reports.State('devicesCount') devicesCount;
  @User.State('project') project;
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.Mutation('setPageTitle') setPageTitle;

  selected_cabinet = {
    text: '',
    value: '',
    iec_meter_id: '',
    iec_customer_id: '',
    iec_supply_id: '',
    total_power_consumption: ''
  };
  cabinets_list = [];
  loading = true;
  loading_report = false;
  selected_period = '';
  tou_history = [];
  set_default = true;
  start_date = null;
  end_date = null;
  start_time = null;
  end_time = null;
  title = '';
  total_power_consumption = '';
  generate_clicked = false;
  graph_data = new Array(11).fill(0);
  graph_dates = new Array(11);

  mounted() {
    this.setPageTitle('Cabinet Power Consumption Report');
    this.generatePageData();
  }

  @Watch('devices')
  @Watch('devicesCount')
  generatePageData(){
    if (this.devices.length !== this.devicesCount) return;
    this.loading = true;
    this.generate_clicked = false;
    this.cabinets_list = [...this.commissionedCabinets_map.values()]
      .filter((device) => (device['meta.device'].cabinet_id))
      .map((device) => ({
        text: device['meta.device'].cabinet_id,
        value: device.id,
        iec_meter_id: device['meta.device'][`cabinet_iec's_meter_id`],
        iec_customer_id: device['meta.device'][`iec's_customer_id`],
        iec_supply_id: device['meta.device'][`iec's_supply_pole_id`],
        total_power_consumption: ''
      }));

    if (this.cabinets_list.length) {
      this.cabinets_list.sort((a, b) => a.text.localeCompare(b.text));
      this.selected_cabinet = this.cabinets_list[0];
      this.initializeGeneralData();
    }
    this.loading = false;
  }

  get periods_list() {
    return [
      { text: this.$t('Last 24 hours'), value: '2days' },
      { text: this.$t('Last week'), value: '8days' },
      { text: this.$t('Last month'), value: '1months' },
      { text: this.$t('Last year'), value: '1years' },
      { text: this.$t('Custom'), value: 'custom' }
    ];
  }

  generateGraphDates() {
    this.graph_dates = [...this.graph_dates.keys()].map((val, index) => {
      return moment()
        .subtract(index + 1, 'days')
        .valueOf();
    });
    this.graph_dates.reverse();
  }

 async generateReport() {
    this.loading_report = true;
    await this.fetchTouStreamHistory();
    this.loading_report = false;
    this.generate_clicked = true;
  }

  async fetchTouStreamHistory() {
    const period =
      this.selected_period === 'custom'
        ? this.getCustomPeriod()
        : { since: this.selected_period };

    const device_id = this.selected_cabinet.value;

    try {
      let history = await API.get(
        Types.DEVICES,
        `${device_id}/streams/${device_id}.tou/history/`,
        period
      );
      history = history.map(([timestamp, string_value]) => {
        try {
          const json_value = JSON.parse(string_value);
          return [timestamp, json_value];
        } catch (e) {
          const temp = string_value.replaceAll(`'`, `"`);
          try {
            const json_value = JSON.parse(temp);
            return [timestamp, json_value];
          } catch (e) {
            return [timestamp, string_value];
          }
        }
      });

      this.tou_history = history
        .filter(
          ([timestamp, value]) =>
            typeof value !== 'string' &&
            typeof value['Summary total energy'] !== 'number'
        )
        .map(([timestamp, value]) => [
          timestamp,
          +value['Summary total energy']
        ]);

      if (this.tou_history.length > 1) {
        this.total_power_consumption = Number(
          this.tou_history.slice(-1)[0][1] - this.tou_history[0][1]
        ).toFixed(3);
        this.title = this.getTitle();
        this.selected_cabinet.total_power_consumption =
          this.total_power_consumption;
        if (this.selected_period !== 'custom') this.generateGraphData();
      } else {
        this.tou_history = [];
        this.title = '';
      }
    } catch (e) {}
  }

  setDefaultDates() {
    this.set_default = true;
    this.setStartDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
    this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
    this.set_default = false;
  }

  setStartDate(start) {
    this.start_date = start;
  }

  setEndDate(end) {
    this.end_date = end;
  }

  getCustomPeriod() {
    if (this.start_date > this.end_date) {
      const temp = this.start_date;
      this.start_date = this.end_date;
      this.end_date = temp;
    }

    return {
      from: moment(this.start_date).set(this.start_time).valueOf(),
      to: moment(this.end_date).set(this.end_time).valueOf()
    };
  }

  generateGraphData() {
    const start = this.graph_dates[0],
      end = moment(this.graph_dates.slice(-1)[0]).set(this.end_time).valueOf();
    const last_ten_days = this.tou_history.filter(
      ([timestamp, value]) => timestamp >= start && timestamp <= end
    );
    last_ten_days.forEach(([timestamp, value], index) => {
      if (index < last_ten_days.length - 1) {
        const next = last_ten_days[index + 1];
        const next_day_power_consumption = Number((next[1] - value).toFixed(3));
        const date_index = this.graph_dates.findIndex(
          (date) =>
            moment(date).tz(this.projectTimezone).format('DD/MM/YYYY') ===
            moment(next[0]).tz(this.projectTimezone).format('DD/MM/YYYY')
        );
        if (date_index > 0)
          this.graph_data[date_index] = next_day_power_consumption;
      }
    });
  }

  initializeGeneralData() {
    this.start_time = {
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    };

    this.end_time = {
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59
    };

    this.generateGraphDates();
  }

  @Watch('start_date')
  @Watch('end_date')
  updateStartDate() {
    this.graph_data = new Array(11).fill(0);
    this.generate_clicked = false;
  }

  @Watch('selected_cabinet')
  @Watch('selected_period')
  initializeDates() {
    this.graph_data = new Array(11).fill(0);
    if (this.selected_period === 'custom') this.setDefaultDates();
    this.generate_clicked = false;
  }

  getTitle() {
    return this.tou_history.length < 3
      ? `${moment(this.tou_history[1][0])
          .tz(this.projectTimezone)
          .format('DD/MM/YYYY')}`
      : `${moment(this.tou_history[1][0])
          .tz(this.projectTimezone)
          .format('DD/MM/YYYY')} - ${moment(
          this.tou_history[this.tou_history.length - 1][0]
        )
          .tz(this.projectTimezone)
          .format('DD/MM/YYYY')}`;
  }
}
